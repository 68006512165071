import Vue from 'vue'
import VueRouter from 'vue-router'
import topMenu from '@/config/topMenu'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/BasicLayout'),
    redirect: '/Index',
    children: [...topMenu],
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('@/views/Exception/404'),
  },
  {
    path: '/official',
    component: () => import('@/layouts/OfficialLayout'),
    redirect: '/official/homePage',
    children: [
      {
        path: '/official/register',
        name: 'Register',
        meta: {
          title: '注册',
        },
        component: () => import('@/views/Official/Register'),
      },
      {
        path: '/official/officialLogin',
        name: 'OfficialLogin',
        meta: {
          title: '官方登录',
        },
        component: () => import('@/views/Official/OfficialLogin'),
      },
      {
        path: '/official/forget',
        name: 'Forget',
        meta: {
          title: '密码重置',
        },
        component: () => import('@/views/Official/ForgetPassword'),
      },
    ],
  },
  {
    path: '/user',
    component: () => import('@/layouts/UserLayout'),
    redirect: '/user/user',
    children: [
      {
        path: '/user/user',
        name: 'Login',
        meta: {
          title: '登录',
        },
        component: () => import('@/views/User/Login'),
      },
    ],
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/Exception/Test'),
  },
]
// hash
const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})

export default router
