/**
 * 资金管理相关接口
 * */
import request from '@/utils/request'
import qs from 'qs'

/**
 * 获取充值二维码
 * */
export function getQRCode(params) {
  return request({
    url: '/commission/mini_recharge_qrcode',
    method: 'get',
    params,
  })
}

/**
 * 获取企业备用金余额及消费记录
 * */
export function getTopupList(params) {
  return request({
    url: '/commission/corp/records',
    method: 'get',
    params,
  })
}

/**
 * 获取短信模板
 * */
export function getMessageTemplate(data) {
  return request({
    url: '/work/shop/getSmsTemplate',
    method: 'post',
    data,
  })
}

/**
 * 设置短信模板
 * */
export function setMessageTemplate(data) {
  return request({
    url: '/work/shop/setSmsTemplate',
    method: 'post',
    data,
  })
}

// 获取自定义模板
export const workSmsTemplateGet = (params) =>
  request.get(`/work/sms_template/get?${qs.stringify(params)}`)

// 设置自定义模板
export const workSmsTemplateUpadte = (params) =>
  request.post('/work/sms_template/update', params)


//定金列表
export const getDepositList = (params) =>
  request.post('/work/deposit/getList', params)

//定金核销
export const setWriteOff = (params) =>
  request.post('/work/deposit/writeOff', params)

//订金设置打印状态
export const setPrint = (params) =>
  request.post('/work/deposit/setPrint', params)

//订金退款
export const refundDeposit = (params) =>
  request.post('/work/deposit/refundDeposit', params)